// export const baseurl = "http://172.16.1.176:1929/"; //local

export const baseurl = "https://apps.maruhanjapanbanklao.com:4449"; // staging

let authentication = `${baseurl}/authentication`;

const Apiconfigs = {
  // Auth Routes

  registrations: `${baseurl}/registrations`,
  login: `${authentication}/login`,

};

export default Apiconfigs;
