import { toast } from "react-toastify";
import { Contract } from "@ethersproject/contracts";
import axios from "axios";
import Apiconfigs from "src/ApiConfig/ApiConfig";
import { values } from "lodash";
const crypto = require('crypto-browserify');
const stream = require('stream-browserify');
window.Buffer = window.Buffer || require("buffer").Buffer;
export function sortAddress(add) {
  const sortAdd = `${add?.slice(0, 6)}...${add?.slice(add.length - 4)}`;
  return sortAdd;
}

export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked();
}

export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library;
}

export function getContract(address, ABI, library, account) {
  return new Contract(address, ABI, getProviderOrSigner(library, account));
}
export const getAPIdata = async ({ params, data, endPoint, headers }) => {
  console.log(params, data, endPoint);
  try {
    let res;
    res = await axios({
      url: endPoint,
      method: "GET",
      params: params,
      headers: headers,
    })
      .then((res) => {
        if (res?.data?.status === 200) {
          // toast.success(res?.data?.message)
          return res?.data?.data;
        }
        if (res?.data?.status === 400) {
          toast.error(res?.data?.message);
          return false;
        }
        if (res?.data?.status === 401) {
          toast.error(res?.data?.message);
          return false;
        }
        if (res?.data?.status === 205) {
          // toast.error(res?.data?.message)
          return false;
        }
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(error.response.data.error);
        return false;
      });
    return res;
  } catch (error) {
    return false;
  }
};

export const putAPIdata = async ({ params, data, endPoint, headers }) => {
  console.log(params, data, endPoint);
  try {
    let res;

    res = await axios({
      url: endPoint,
      method: "PUT",
      params: params,
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res?.data?.status === 200) {
          toast.success(res?.data?.message);
          return res?.data?.data;
        }
        if (res?.data?.status === 400) {
          toast.error(res?.data?.message);
          return false;
        }
        if (res?.data?.status === 401) {
          toast.error(res?.data?.message);
          return false;
        }
      })
      .catch((error) => {
        toast.error(error.response.message);
        return false;
      });
    return res;
  } catch (error) {
    return false;
  }
};

export const postAPIdata = async ({ params, data, endPoint, headers }) => {
  try {
    let res;

    res = await axios({
      url: endPoint,
      method: "POST",
      data: data,
      headers: headers,
    })
      .then((res) => {
        if (res?.status === 200) {
          return res?.data;
        }
        if (res?.data?.status === 400) {
          // toast.error(res?.data?.message);
          return res?.data;
        }
        if (res?.data?.status === 401) {
          // toast.error(res?.data?.message);
          return false;
        }
        if (res?.data?.status === 205) {
          return false;
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.data.status === 401) {
          toast.error(error.response.data.error);
          return false;
        }
        return false;
      });
    return res;
  } catch (error) {
    return false;
  }
};

export const deleteAPIdata = async ({ params, data, endPoint, headers }) => {
  console.log(params, data, endPoint);
  try {
    let res;

    res = await axios({
      url: endPoint,
      method: "DELETE",
      params: params,
      data: data,
      headers: headers,
    })
      .then((res) => {
        if (res?.data?.status === 200) {
          toast.success(res?.data?.message);
          return res?.data?.data;
        }
        if (res?.data?.status === 400) {
          toast.error(res?.data?.message);
          return false;
        }
        if (res?.data?.status === 401) {
          toast.error(res?.data?.message);
          return false;
        }
      })
      .catch((error) => {
        toast.error(error.response.message);
        return false;
      });
    return res;
  } catch (error) {
    return false;
  }
};

export const encryptRes = async (data) => {

  const KEY = Buffer.from("de2bfe0d0b55800533c4b0ab7a090f23be6e8b2a0c8a6c7d2a98577f51a7dd1374e1f5b64e3066e262de83975758e598", 'hex');
  const IV = KEY.slice(0, 16)
  const seckey = KEY.slice(16, KEY.length)
  console.log('jdsfhdsjfh', KEY, IV, seckey)

  let key = seckey, iv = IV;

  const contexString = JSON.stringify(data);
  const byte = crypto.createCipheriv('aes-256-gcm', ConvertHexToBuffer(key), ConvertHexToBuffer(iv),
    {
      authTagLength: 16
    }
  );
  let encrypted = byte.update(contexString, 'utf-8', 'hex');
  encrypted += byte.final('hex');
  const authTag = byte.getAuthTag()?.toString('hex');
  console.log('dsfhgdsfgsdf', [authTag, encrypted]?.join(''))
  return [authTag, encrypted]?.join('')
}

export const decryptRes = async (data) => {
  const KEY = Buffer.from("de2bfe0d0b55800533c4b0ab7a090f23be6e8b2a0c8a6c7d2a98577f51a7dd1374e1f5b64e3066e262de83975758e598", 'hex');
  const IV = KEY.slice(0, 16)?.toString('hex')
  const seckey = KEY.slice(16, KEY.length)?.toString('hex')

  let key = seckey, iv = IV;

  let chiphers = ConvertHexToBuffer(data);
  const authTag = chiphers.slice(0, 16)?.toString('hex');
  const chipher = chiphers.slice(16, chiphers?.length)?.toString('hex');
  const byte = crypto.createDecipheriv('aes-256-gcm', ConvertHexToBuffer(key), ConvertHexToBuffer(iv));
  byte.setAuthTag(ConvertHexToBuffer(authTag));
  let decrypted = byte.update(chipher, 'hex', 'utf-8');
  const Context = JSON.parse(decrypted);
  return Context;
}

function ConvertHexToBuffer(message) {
  return Buffer.from(message, 'hex');
}